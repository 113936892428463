<template>
    <div >
      <b-card-code title="Add Package">
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <!-- title -->
              <b-col md="6">
                <b-form-group label="Name ar" label-for="Name ar">
                  <validation-provider
                    #default="{ errors }"
                    name="Name ar"
                    rules="required"
                    >
                    <b-form-input
                      id="Name ar"
                      v-model="packageData.name_ar"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name ar"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


              <b-col md="6">
                <b-form-group label="Name en" label-for="Name en">
                  <validation-provider
                    #default="{ errors }"
                    name="Name en"
                    rules="required"
                    >
                    <b-form-input
                      id="Name en"
                      v-model="packageData.name_en"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name en"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            

                   <!-- Price -->
                   <b-col md="12">
                <b-form-group label="Price" label-for="Price">
                  <validation-provider
                    #default="{ errors }"
                    name="Price"
                    rules="required|regex:\b\d+(?:\.\d\d?)?\b" >
                    <b-form-input
                      type="number"
                      id="Price"
                      v-model="packageData.price"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Price"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

           
                <!-- Number of ads -->
                <b-col md="6">
                <b-form-group label="Number of ads" label-for="Number of ads">
                  <validation-provider
                    #default="{ errors }"
                    name="Number of ads"
                    rules="required|regex:\b\d+(?:\.\d\d?)?\b" >
                    <b-form-input
                      type="number"
                      id="Number of ads"
                      v-model="packageData.ads_limit"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Number of ads"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


              
                <!-- Days until expiry -->
                <b-col md="6">
                <b-form-group label="Days until expiry" label-for="Days until expiry">
                  <validation-provider
                    #default="{ errors }"
                    name="Days until expiry"
                    rules="required|regex:\b\d+(?:\.\d\d?)?\b" >
                    <b-form-input
                      type="number"
                      id="Days until expiry"
                      v-model="packageData.days_until_expiry"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Days until expiry"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>


                  <!-- category -->
                  <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="category"
                    rules="required"
                  >
                    <b-form-group
                      label="Category"
                      label-for="category"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        id="category"
                        v-model="packageData.category_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="categoryOption"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6"></b-col>
                
              <!-- payment provider -->
              <p class="ml-2"> <h4> <b class="mt-4"> Add Payments :  </b></h4></p>
              <b-col md="12" v-for="(paymet_method, index)  in packageData.paymet_methods"  :key="index"  ref="row">
                
                <hr>
              
              <b-container class="bv-example-row">
                  <b-row>
                    <b-col>
                              <validation-provider
                              #default="{ errors }"
                              name="payment"
                              rules="required"
                            >
                              <b-form-group
                                label="Payments"
                                label-for="payment"
                                :state="errors.length > 0 ? false : null"
                              >
                                <v-select
                                  id="payment"
                                  v-model="paymet_method.payments"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="payments"
                                  :selectable="
                                    (option) => !option.value.includes('select_value')
                                  "
                                  label="text"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </b-form-group>
                            </validation-provider>
                    </b-col>
                    <b-col>
                       <!-- provider_fees  -->
                            <b-col md="6">
                                <b-form-group label="Payment Fees" label-for="Payment Fees">
                                    <validation-provider #default="{ errors }" name="Fees" rules="required">
                                        <b-form-input type="number" v-model="paymet_method.fees"
                                            :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                    </b-col>
                    <b-col>
                      <b-form-group label="Is Active ?" label-for="active">
                        <validation-provider #default="{ errors }" name="Active" rules="required">
                            <b-form-checkbox switch v-model="paymet_method.is_active">
                                <p v-if="paymet_method.is_active">
                                    Active
                                </p>
                                <p v-else>Not Active</p>
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                     </b-form-group>
                    </b-col>

                    <b-col>
                        <!-- Remove Button -->
                        <b-button
                          size="sm"
                          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          v-show="index > 0"
                          class="mt-0 mt-md-2"
                          @click="subpaymetmethods(index)"
                        >
                          <feather-icon icon="XIcon" class="mr-25" />
                          <span>Remove</span>
                        </b-button>
           

                    </b-col>
                  </b-row>
                </b-container>


              <hr>
            </b-col>

            <b-col lg="12" md="12" class="mb-50">
              <b-button 
                  class="ml-4"
                  size="md"
                  variant="outline-primary"
                  type="submit"
                  @click.prevent="addpaymetmethods()"
                >
                <feather-icon icon="PlusIcon" class="mr-25" />
                  Add New Payment
             </b-button> 
           </b-col>

          

            <hr>
               <!-- Portal Option -->
               <b-col md="6" class="mt-4">
              <b-form-group label="Is Active Package?" label-for="active">
                  <validation-provider #default="{ errors }" name="Active" rules="required">
                      <b-form-checkbox switch v-model="packageData.is_active">
                          <p v-if="packageData.is_active">
                              Active
                          </p>
                          <p v-else>Not Active</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
              </b-form-group>
          </b-col>


              
               <!-- Description  ar -->
               <b-col md="12">
                      <b-form-group label="Description ar" label-for="description">
                        <validation-provider
                          #default="{ errors }"
                          name="description"
                          rules="required"
                        >
                          <vue-editor
                            :id="'description'"
                            v-model="packageData.description_ar"
                            :state="errors.length > 0 ? false : null"
                            rows="1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                </b-col>


                    <!-- Description en -->
               <b-col md="12">
                      <b-form-group label="Description en" label-for="description">
                        <validation-provider
                          #default="{ errors }"
                          name="description en"
                          rules="required"
                        >
                          <vue-editor
                            id="descriptionen"
                            v-model="packageData.description_en"
                            :state="errors.length > 0 ? false : null"
                            rows="2"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                </b-col>


              

              
              <b-col cols="12">
                <hr />
              </b-col>
              <!--submit button -->
              <b-col>
                <b-button
                  size="lg"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                  :disabled="disable_submit"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
      <!-- error handelr -->
      <b-alert
        v-height-fade.appear
        fade
        :show="showDismissibleAlert"
        @dismissed="showDismissibleAlert = false"
        variant="danger"
      >
        <h4 class="alert-heading">Alert</h4>
        <div class="alert-body">
          <ul v-for="(values, index) in errors_back" :key="index">
            <li v-for="(value, valIndex) in values" :key="valIndex">
              {{ value }}
            </li>
          </ul>
        </div>
      </b-alert>
    </div>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code'
  import { heightTransition } from '@core/mixins/ui/transition'
  import { required, integer } from '@validations'
  import { VueEditor } from 'vue2-editor'
  export default {
    components: {
      BCardCode,
      VueEditor,
   
      
    },
    
    mixins: [heightTransition],
    data() {
      return {
        showDismissibleAlert: false,

       
        packageData: {
            name_ar: '',
            name_en: '',
            description_ar: '',
            description_en: '',
            price: null,
            ads_limit:null,
            is_active: true,
            paymet_methods: [{
              is_active: true
            }]
        },
        categoryOption: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
        errors_back: [],
        required,
        integer,

        paymet_methods:[{}],
        payments:  [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      disable_submit: true,
      }
    },
    created() {
      this.fetchData();
      // get categories data for selectOption
      axios
      .get('categories')
      .then((result) => {
        const data = result.data.data
        for (let cat in data) {
          if (data[cat].translation != null) {
            this.categoryOption.push({
              value: data[cat].id.toString(),
              text: data[cat].translation.name
            })
          } else {
            this.categoryOption.push({
              value: data[cat].id.toString(),
              text: 'undefinded',
            })
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })


    },
    watch:{
     

      packageData: function () {
        if(
            this.packageData.name_ar != null &&
            this.packageData.name_en !=  null &&
            this.packageData.description_ar != null &&
            this.packageData.description_en != null &&
            this.packageData.price != null &&
            this.packageData.ads_limit != null &&
            this.packageData.is_active != null 
        ){
          this.disable_submit = false;
        }else{
          this.disable_submit = true;
        }
            
     },
    },
    computed: {
        sortPaymentOptions() {
            // Create an options list from our fields
            return this.payments
                .filter((f) => true)
                .map((f) => ({
                    text: f.name,
                    value: f.id,
                }))
        },
    },
    methods: {

      fetchData() {
            axios
                .get('getPaymentProvidersSession')
                .then((result) => {
                  const data = result.data.data;

                    for (let cat in data) {
                      this.payments.push({
                        value: data[cat].id.toString(),
                        text: data[cat].name
                      });
                    }
                })
                .catch((err) => {
                    //console.log(err)
                    this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                            this.errors_back.push({
                                error: err.response.data.data,
                            })
                        } else {
                            this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                            error: 'internal server error',
                        })
                        this.showDismissibleAlert = true
                    }
                });

      },
      validationForm() {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            
            axios
              .post('packages', this.packageData)
              .then((result) => {
                this.$swal({
                  position: 'center',
                  icon: 'success',
                  title: 'Your Package has been saved',
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.$router.push('/packages/index')
              })
              .catch((err) => {
                this.$swal({
                  position: 'center',
                  icon: 'error',
                  title: 'Error!',
                  showConfirmButton: false,
                  timer: 1500,
                })
                this.errors_back.length = 0
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    })
                  } else {
                    this.errors_back = err.response.data.data
                  }
                  this.showDismissibleAlert = true
                } else {
                  this.errors_back = []
                  this.errors_back.push({
                    error: 'internal server error',
                  })
                  this.showDismissibleAlert = true
                }
              })
          }
        })
      },



      addpaymetmethods(){
        this.packageData.paymet_methods.push({});
      },
      subpaymetmethods(index){
        this.packageData.paymet_methods.splice(index, 1);
      },


    
      isString(value) {
        return typeof value === 'string' || value instanceof String
      },
   
      
    },
  }
  </script>
   
  <style lang="scss">
  
  
  @import '~@core/scss/base/bootstrap-extended/include';
  @import '~@core/scss/base/components/variables-dark';
  .repeater-form {
    transition: 0.35s height;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  p {
    margin: 0;
  }
  .app-auto-suggest {
    position: relative;
  }
  .auto-suggest-suggestions-list {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    border-radius: 6px;
    position: absolute;
    top: calc(100% + 1rem);
    background-color: white;
    width: 100%;
  }
  .suggestion-group-title {
    font-weight: 500;
    padding: 0.75rem 1rem 0.25rem;
  }
  .suggestion-group-suggestion {
    padding: 0.75rem 1rem;
  }
  .suggestion-current-selected {
    background-color: $body-bg;
    .dark-layout & {
      background-color: $theme-dark-body-bg;
    }
  }
  </style>