var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-code',{attrs:{"title":"Add Package"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name ar","label-for":"Name ar"}},[_c('validation-provider',{attrs:{"name":"Name ar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Name ar","state":errors.length > 0 ? false : null,"placeholder":"Name ar"},model:{value:(_vm.packageData.name_ar),callback:function ($$v) {_vm.$set(_vm.packageData, "name_ar", $$v)},expression:"packageData.name_ar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name en","label-for":"Name en"}},[_c('validation-provider',{attrs:{"name":"Name en","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Name en","state":errors.length > 0 ? false : null,"placeholder":"Name en"},model:{value:(_vm.packageData.name_en),callback:function ($$v) {_vm.$set(_vm.packageData, "name_en", $$v)},expression:"packageData.name_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Price","label-for":"Price"}},[_c('validation-provider',{attrs:{"name":"Price","rules":"required|regex:\\b\\d+(?:\\.\\d\\d?)?\\b"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"Price","state":errors.length > 0 ? false : null,"placeholder":"Price"},model:{value:(_vm.packageData.price),callback:function ($$v) {_vm.$set(_vm.packageData, "price", $$v)},expression:"packageData.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Number of ads","label-for":"Number of ads"}},[_c('validation-provider',{attrs:{"name":"Number of ads","rules":"required|regex:\\b\\d+(?:\\.\\d\\d?)?\\b"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"Number of ads","state":errors.length > 0 ? false : null,"placeholder":"Number of ads"},model:{value:(_vm.packageData.ads_limit),callback:function ($$v) {_vm.$set(_vm.packageData, "ads_limit", $$v)},expression:"packageData.ads_limit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Days until expiry","label-for":"Days until expiry"}},[_c('validation-provider',{attrs:{"name":"Days until expiry","rules":"required|regex:\\b\\d+(?:\\.\\d\\d?)?\\b"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"Days until expiry","state":errors.length > 0 ? false : null,"placeholder":"Days until expiry"},model:{value:(_vm.packageData.days_until_expiry),callback:function ($$v) {_vm.$set(_vm.packageData, "days_until_expiry", $$v)},expression:"packageData.days_until_expiry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Category","label-for":"category","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoryOption,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.packageData.category_id),callback:function ($$v) {_vm.$set(_vm.packageData, "category_id", $$v)},expression:"packageData.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}}),_c('p',{staticClass:"ml-2"}),_c('h4',[_c('b',{staticClass:"mt-4"},[_vm._v(" Add Payments : ")])]),_c('p'),_vm._l((_vm.packageData.paymet_methods),function(paymet_method,index){return _c('b-col',{key:index,ref:"row",refInFor:true,attrs:{"md":"12"}},[_c('hr'),_c('b-container',{staticClass:"bv-example-row"},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"payment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Payments","label-for":"payment","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"payment","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.payments,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(paymet_method.payments),callback:function ($$v) {_vm.$set(paymet_method, "payments", $$v)},expression:"paymet_method.payments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Payment Fees","label-for":"Payment Fees"}},[_c('validation-provider',{attrs:{"name":"Fees","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(paymet_method.fees),callback:function ($$v) {_vm.$set(paymet_method, "fees", $$v)},expression:"paymet_method.fees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Is Active ?","label-for":"active"}},[_c('validation-provider',{attrs:{"name":"Active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(paymet_method.is_active),callback:function ($$v) {_vm.$set(paymet_method, "is_active", $$v)},expression:"paymet_method.is_active"}},[(paymet_method.is_active)?_c('p',[_vm._v(" Active ")]):_c('p',[_vm._v("Not Active")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}},{name:"show",rawName:"v-show",value:(index > 0),expression:"index > 0"}],staticClass:"mt-0 mt-md-2",attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.subpaymetmethods(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Remove")])],1)],1)],1)],1),_c('hr')],1)}),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"12","md":"12"}},[_c('b-button',{staticClass:"ml-4",attrs:{"size":"md","variant":"outline-primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addpaymetmethods()}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_vm._v(" Add New Payment ")],1)],1),_c('hr'),_c('b-col',{staticClass:"mt-4",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Is Active Package?","label-for":"active"}},[_c('validation-provider',{attrs:{"name":"Active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.packageData.is_active),callback:function ($$v) {_vm.$set(_vm.packageData, "is_active", $$v)},expression:"packageData.is_active"}},[(_vm.packageData.is_active)?_c('p',[_vm._v(" Active ")]):_c('p',[_vm._v("Not Active")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Description ar","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{attrs:{"id":'description',"state":errors.length > 0 ? false : null,"rows":"1"},model:{value:(_vm.packageData.description_ar),callback:function ($$v) {_vm.$set(_vm.packageData, "description_ar", $$v)},expression:"packageData.description_ar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Description en","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description en","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-editor',{attrs:{"id":"descriptionen","state":errors.length > 0 ? false : null,"rows":"2"},model:{value:(_vm.packageData.description_en),callback:function ($$v) {_vm.$set(_vm.packageData, "description_en", $$v)},expression:"packageData.description_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',[_c('b-button',{attrs:{"size":"lg","variant":"primary","type":"submit","disabled":_vm.disable_submit},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Submit ")])],1)],2)],1)],1)],1),_c('b-alert',{directives:[{name:"height-fade",rawName:"v-height-fade.appear",modifiers:{"appear":true}}],attrs:{"fade":"","show":_vm.showDismissibleAlert,"variant":"danger"},on:{"dismissed":function($event){_vm.showDismissibleAlert = false}}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Alert")]),_c('div',{staticClass:"alert-body"},_vm._l((_vm.errors_back),function(values,index){return _c('ul',{key:index},_vm._l((values),function(value,valIndex){return _c('li',{key:valIndex},[_vm._v(" "+_vm._s(value)+" ")])}),0)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }